<template>
  <div>
    <b-container fluid>
      <b-row v-if="release == null" class="mt-5">
        <b-col class="text-center">
          <b-spinner label="Spinning"></b-spinner>
        </b-col>
      </b-row>

      <b-row v-if="release == false" class="mt-5">
        <b-col class="text-center">
          Album not found
        </b-col>
      </b-row>
    </b-container>

    <router-view  v-if="release != null && release != false" 
      :release="release" 
      @takedown="takedown"
      @save-outlet-settings="saveOutletSettings"
       />

      
  </div>
</template>

<script>
  export default {
    name: 'ReleaseDetailContainer',
    data () {
      return {
        release: null,
      }
    },
    props: {
      id: String
    },
    methods: {
      saveOutletSettings (settings) {
        this.release = null
        this.$http.put('albums/'+this.id, {
            Outlets: settings    
          })
          .then(response => {
            this.$router.push({ name: 'release-detail' })
            this.release = response.data
            this.$notify({
              type: 'success',
              text: 'Outlet Settings Saved'
            });
          })
          .catch((error) => {
            this.$notify({
              type: 'danger',
              text: 'An error occurred while saving: ' + error.response.data
            });
          });
      },
      takedown (reason) {
        this.release = null
        this.$http.put('albums/'+this.id, {
            ProductDetails: { DeliveryStatus: 83 },
            Reason: reason,
          })
          .then(response => {
            this.release = response.data
            this.$notify({
              type: 'success',
              text: 'Release will be taken down'
            });
          })
          .catch((error) => {
            console.log(error)
          });
      }
    },
    mounted () {
      this.$http.get('albums/'+this.id, {
          
        })
        .then(response => {
          this.release = response.data
        })
        .catch((error) => {
          console.log(error)
        });
    }
  }
</script>